.home-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.logo {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 2rem;
}

.spotify-embed-container {
  width: 100%;
  max-width: 500px;
  margin-bottom: 2rem;
  position: relative;
}

.spotify-iframe {
  width: 100%;
  height: 352px;
  border-radius: 12px;
}

.video-container2 {
  width: 100%;
  max-width: 500px;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  margin-top: 2rem;
}

.video-container2 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Add text alignment */
h1, p {
  text-align: center;
  width: 100%;
  max-width: 500px;
}

/* Add some spacing between text elements */
h1 {
  margin-bottom: 1rem;
  font-size: 24px;
}

p {
  margin-bottom: 0.5rem;
  font-size: 16px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .home-container2 {
    padding: 15px;
  }
  
  /* Make text 50% smaller on mobile */
  h1 {
    font-size: 12px;  /* reduced from 24px */
  }
  
  p {
    font-size: 8px;   /* reduced from 16px */
  }
}