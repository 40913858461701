body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

.home-container {
  width: 100%;
  max-width: 800px;
  padding: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 20px auto;
}

input, .StripeElement {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #000000;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 20px auto;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 80%;
  height: 80%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.button-container button {
  flex: 1;
  margin: 0 5px;
}

.logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.download-form {
  margin-top: 20px;
}

.download-form label {
  display: block;
  margin-bottom: 10px;
}

.download-form input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.download-form button {
  padding: 10px 20px;
  border: none;
  background-color: #000000;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.download-form button:hover {
  background-color: #070707;
}

.message {
  margin-top: 10px;
  color: #d9534f; /* Error color */
}



.download-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #000000;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}

.download-button:hover {
  background-color: #fe5656;
}
